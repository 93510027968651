import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Header",
  "type": "Layout",
  "icon": "gridHorizontalLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Header`}</strong>{` is part of the main layout of your application. It has a fixed height and can
be either stuck to the top of the window or floating, depending on the use case. Usually
in an application there should be only one `}<strong parentName="p">{`Header`}</strong>{` component.`}</p>
    <p>{`Use `}<strong parentName="p">{`Header`}</strong>{` for the logo of the application, as well as for some global actions
such as `}<em parentName="p">{`user notifications`}</em>{`, `}<em parentName="p">{`search`}</em>{` and `}<em parentName="p">{`settings`}</em>{`.`}</p>
    <p>{`Default `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` of the header is `}<inlineCode parentName="p">{`1120px`}</inlineCode>{`. If you want to stretch the header from the
left to the right of the screen, use `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{` prop.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true background=layout padding=true",
        "live": "true",
        "background": "layout",
        "padding": "true"
      }}>{`<Header style={{ paddingLeft: "32px", paddingRight: "32px" }}>
  <Logo />
  <HeaderDivider />
  <HeaderNavigation>
    <HeaderNavigationLink active={true} onClick={() => {}}>
      All type
    </HeaderNavigationLink>
    <HeaderNavigationLink onClick={() => {}}>Withdrawals</HeaderNavigationLink>
    <HeaderNavigationLink onClick={() => {}}>Deposit</HeaderNavigationLink>
    <HeaderNavigationLink onClick={() => {}}>Transfer</HeaderNavigationLink>
  </HeaderNavigation>
</Header>
`}</code></pre>
    <h1>{`Props`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the header will be `}<inlineCode parentName="td">{`position: fixed`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the header`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fullWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stretches the content of the header`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      